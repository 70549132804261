import * as React from "react";
import { Accessory } from "@sproutsocial/seeds-react-input";
import Box from "@sproutsocial/seeds-react-box";
import Icon from "@sproutsocial/seeds-react-icon";
import Token from "@sproutsocial/seeds-react-token";
import Container from "./styles";
import { asTokenSpec, delimitersAsRegExp } from "./util";
import type { TypeTokenInputProps, TypeTokenSpec } from "./TokenInputTypes";
import { TokenScreenReaderStatus } from "./TokenScreenReaderStatus";

type TypeState = {
  prevProps: TypeTokenInputProps;
  hasFocus: boolean;
  activeToken: string | null | undefined;
  value: string;
};

const DefaultDelimiters = [",", "Enter"];
const ControlledPropNames: (keyof TypeState)[] = [
  "value",
  "hasFocus",
  "activeToken",
];

export default class TokenInput extends React.Component<
  TypeTokenInputProps,
  TypeState
> {
  delimiterMatcher: RegExp;

  constructor(props: TypeTokenInputProps) {
    super(props);
    const { hasFocus, activeToken, value, delimiters } = props;
    this.delimiterMatcher = delimitersAsRegExp(delimiters || DefaultDelimiters);
    this.state = {
      prevProps: props,
      hasFocus: hasFocus || false,
      activeToken: activeToken,
      value: value || "",
    };
  }

  static getDerivedStateFromProps(
    props: Readonly<TypeTokenInputProps>,
    state: TypeState
  ) {
    const { prevProps } = state;
    const modifiedState: Partial<TypeState> = { prevProps: props };
    ControlledPropNames.forEach((propName) => {
      const currentProp = props[propName as keyof TypeTokenInputProps];

      // @ts-ignore: TODO - fix state types for prevProps
      if (currentProp !== prevProps[propName]) {
        modifiedState[propName] = currentProp;
      }
    });
    modifiedState.prevProps = props;
    return modifiedState;
  }

  isDelimiter(keyName: string) {
    const { delimiters = DefaultDelimiters } = this.props;
    return delimiters.includes(keyName);
  }

  spawnNewTokens(texts: string[]) {
    const {
      onAddToken,
      onChangeTokens,
      tokenMaxLength: max = Infinity,
      tokens = [],
    } = this.props;
    const tokenSpecs = texts.map((text) => asTokenSpec(text.slice(0, max)));

    if (onAddToken) {
      tokenSpecs.forEach(onAddToken);
    } else if (onChangeTokens) {
      onChangeTokens(tokens.concat(tokenSpecs));
    }

    this.setState({
      value: "",
    });
  }

  deleteToken(tokenId?: string) {
    const { onRemoveToken, onChangeTokens, tokens = [] } = this.props;
    const count = tokens.length;
    if (count === 0) return;
    const id = tokenId ?? tokens[count - 1]?.id;

    if (onRemoveToken) {
      onRemoveToken(id ? id : "");
    } else if (onChangeTokens) {
      onChangeTokens(tokens.filter((tokenSpec) => tokenSpec.id !== id));
    }

    this.setState({
      value: "",
    });
  }

  handleChangeText = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const { onChange } = this.props;
    this.setState({
      value,
    });
    onChange?.(e, value);
  };

  handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({
      hasFocus: true,
    });
    onFocus?.(e);
  };

  handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);
    this.setState({
      hasFocus: false,
    });
  };

  handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    this.props.onKeyUp?.(e, e.currentTarget.value);
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onKeyDown } = this.props;
    const { key, currentTarget } = e;
    const text = currentTarget.value;
    if (onKeyDown) onKeyDown(e, text);

    // keyPress event runs before change
    // Prevent event from bubbling up and calling change, which can lead to comma in value
    if (this.isDelimiter(key)) {
      if (text) {
        this.spawnNewTokens([text]);
        e.preventDefault();
      }
    } else if (key === "Backspace") {
      if (text === "") {
        this.deleteToken();
      }
    }
  };

  handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const text = e.clipboardData.getData("text");
    const { onPaste } = this.props;
    if (onPaste) onPaste(e, text);
    const subtexts = text.split(this.delimiterMatcher);
    const texts = subtexts.filter((subtext) => subtext.length);

    if (texts.length > 1) {
      this.spawnNewTokens(texts);
      e.preventDefault();
    }
  };

  handleClickToken = (
    e: React.SyntheticEvent<Element, Event>,
    token: TypeTokenSpec
  ) => {
    const { onClickToken, disabled } = this.props;
    if (onClickToken) onClickToken(e, token.id);

    if (!disabled) {
      this.deleteToken(token.id);
    }
  };

  renderToken(token: TypeTokenSpec): React.ReactNode {
    const { iconName: defaultIconName, disabled } = this.props;
    const activeId = this.state.activeToken;
    const {
      id,
      iconName: tokenIconName,
      iconProps = { "aria-hidden": true },
      value,
      valid,
      hasWarning,
      tokenProps: { onClick, ...restTokenProps } = {},
    } = token;
    const iconName = tokenIconName || defaultIconName;
    const isActive = activeId === id;
    return (
      <Token
        id={id}
        onClick={(e) => {
          this.handleClickToken(e, token);
          onClick?.(e);
        }}
        valid={valid}
        hasWarning={hasWarning}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        active={isActive}
        disabled={disabled}
        {...restTokenProps}
      >
        <Box display="flex" alignItems="center">
          {iconName && (
            <Icon name={iconName} size="mini" pr={300} {...iconProps} />
          )}
          {value}
        </Box>
      </Token>
    );
  }

  renderTokens(tokens: TypeTokenSpec[]): React.ReactNode {
    return tokens.map<React.ReactNode>((token) => (
      <div key={token.id} className="TokenInput-token">
        {this.renderToken(token)}
      </div>
    ));
  }

  override render() {
    const {
      autoFocus,
      autocomplete,
      disabled,
      isInvalid,
      hasWarning,
      id,
      name,
      placeholder,
      required,
      elemBefore,
      elemAfter,
      maxLength,
      ariaDescribedby,
      ariaLabel,
      innerRef,
      // These functions are used in the class functions above, but need to be extracted in order for `rest` to be correct
      /* eslint-disable @typescript-eslint/no-unused-vars */
      value,
      onAddToken,
      onRemoveToken,
      onChangeTokens,
      onClickToken,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      onPaste,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      inputProps = {},
      qa = {},
      tokens,
      ...rest
    } = this.props;
    const { state } = this;
    return (
      <Container
        hasBeforeElement={!!elemBefore}
        hasAfterElement={!!elemAfter}
        disabled={disabled}
        invalid={!!isInvalid}
        warning={hasWarning}
        focused={state.hasFocus}
        {...rest}
      >
        {elemBefore && <Accessory before>{elemBefore}</Accessory>}

        {tokens && this.renderTokens(tokens)}

        <TokenScreenReaderStatus tokens={tokens} />

        <input
          aria-describedby={ariaDescribedby}
          aria-invalid={!!isInvalid}
          aria-label={ariaLabel}
          autoFocus={autoFocus}
          autoComplete={autocomplete}
          disabled={disabled}
          id={id}
          name={name}
          placeholder={placeholder}
          type="text"
          required={required}
          value={state.value}
          maxLength={maxLength}
          onBlur={this.handleBlur}
          onChange={this.handleChangeText}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onPaste={this.handlePaste}
          ref={innerRef}
          data-qa-input={name || ""}
          data-qa-input-isdisabled={!!disabled}
          data-qa-input-isrequired={!!required}
          {...qa}
          {...inputProps}
        />

        {elemAfter && <Accessory after>{elemAfter}</Accessory>}
      </Container>
    );
  }
}
