import type { GetItemPropsOptions } from "downshift";
import type { TypeMenuItemRoles } from "../constants";
import type { TypeInternalItemProps } from "../types";
import type {
  TypeBorderSystemProps,
  TypeColorSystemProps,
  TypeFlexboxSystemProps,
  TypeGridSystemProps,
  TypeLayoutSystemProps,
  TypePositionSystemProps,
  TypeSpaceSystemProps,
  TypeTypographySystemProps,
} from "@sproutsocial/seeds-react-system-props";

export interface TypeMenuItemStyledProps
  extends TypeBorderSystemProps,
    TypeColorSystemProps,
    TypeFlexboxSystemProps,
    TypeGridSystemProps,
    TypeLayoutSystemProps,
    TypePositionSystemProps,
    TypeSpaceSystemProps,
    TypeTypographySystemProps {}

export const INPUT_TYPES = {
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SWITCH: "switch",
  ICON: "icon",
} as const;
export type InputType = (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];

export interface TypeMenuItemProps
  extends TypeMenuItemStyledProps,
    Omit<
      GetItemPropsOptions<TypeInternalItemProps>,
      | "as"
      | "item"
      | "ref"
      | keyof TypeMenuItemStyledProps
      | keyof TypeInternalItemProps
    >,
    Omit<TypeInternalItemProps, "index"> {
  children: React.ReactNode;
  disabled?: boolean;
  role?: TypeMenuItemRoles;
  innerRef?: React.Ref<any>;
  $highlighted?: boolean;

  // props for options
  active?: boolean;
  inputType?: InputType;
  inputLabelId?: string;
}
