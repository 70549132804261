import React from "react";

import type { TypeMenuItemProps } from "../MenuItem";
import type { TypeMenuRootProps } from "../MenuRoot";
import type { TypeAutocompleteProps } from "./AutocompleteTypes";
import { SingleAutocomplete } from "./SingleAutocomplete";
import { MultiAutocomplete } from "./MultiAutocomplete";

/**
 * @link https://seeds.sproutsocial.com/components/autocomplete/
 *
 * @description A Autocomplete allows selecting a {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/MenuItem | MenuItems} to be selected from a list or groups of lists to populate an input.
 *
 */
export const Autocomplete = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  multiSelect,
  ...rest
}: TypeAutocompleteProps<I>) => {
  if (multiSelect) {
    return <MultiAutocomplete {...rest} />;
  }

  return <SingleAutocomplete {...rest} />;
};
