export type TypeReducer<S extends Object, A extends { changes: Partial<S> }> = (
  state: S,
  action: A
) => Partial<S>;
export const reduceReducers =
  <S extends Object, A extends { changes: Partial<S> }>(
    ...reducers: (undefined | TypeReducer<S, A>)[]
  ): TypeReducer<S, A> =>
  (state, action) =>
    reducers.reduce(
      (changes, nextReducer) =>
        nextReducer
          ? nextReducer(state, {
              ...action,
              changes,
            })
          : changes,
      action.changes as Partial<S>
    );
