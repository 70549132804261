import React from "react";
import {
  useItemFiltering,
  type TypeGetIsItemVisibleFn,
} from "../hooks/useItemFiltering";
import {
  MenuContentContext,
  useMenuContentContext,
  type TypeDownshiftSelectReturnValue,
} from "../MenuContext";
import {
  MenuTokenInput,
  type TypeMenuTokenInputProps,
} from "../MenuTokenInput";

export interface TypeMenuSearchTokenInputProps extends TypeMenuTokenInputProps {
  /* A function that can be passed to override the default filtering logic */
  getIsItemVisible?: TypeGetIsItemVisibleFn;
}

/**
 * @link https://seeds.sproutsocial.com/components/menu-search-token-input/
 *
 * @description MenuSearchTokenInput is search token input that can be added to the MenuHeader to filter the items.
 */
export const MenuSearchTokenInput = ({
  getIsItemVisible,
  getTokenProps,
  onChange,
  inputProps: { value: inputPropsValue, ...inputProps } = {},
  value,
  ...tokenInputProps
}: TypeMenuSearchTokenInputProps) => {
  const {
    items,
    itemToString,
    setHiddenItemsMap,
    isOpen,
    getToggleButtonProps,
  } = useMenuContentContext();
  const { updateFilteredItems } = useItemFiltering({
    allMenuItems: items,
    itemToString,
    getIsItemVisible,
    setHiddenItemsMap,
  });

  // set controlled value
  const controlledValue =
    value || inputPropsValue !== undefined ? `${inputPropsValue}` : undefined;

  // internal state helps to keep track of the input value since it sometimes resets on its own
  const [internalValue, setInternalValue] = React.useState(
    controlledValue || ""
  );

  // any time state is updated, also update the filtered items
  const updateFilteredState = React.useCallback(
    // defaults to an empty string if no value is passed
    (value: string = "") => {
      setInternalValue(value);
      updateFilteredItems({ inputValue: value });
    },
    [updateFilteredItems]
  );

  React.useEffect(() => {
    // handles when input is controlled to keep internal state updated
    updateFilteredState(controlledValue);
  }, [controlledValue]);

  React.useEffect(() => {
    // handles when open/close state changes
    updateFilteredState(controlledValue);
  }, [isOpen]);

  const toggleButtonProps: Partial<
    ReturnType<Required<TypeDownshiftSelectReturnValue>["getToggleButtonProps"]>
  > = getToggleButtonProps?.() || {};

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      // prevents selecting items with space key when input is focused
      if (e.key === " ") return;
      toggleButtonProps.onKeyDown?.(e);
    },
    [toggleButtonProps.onKeyDown]
  );

  return (
    <MenuTokenInput
      onChange={(e, value) => {
        updateFilteredState(value);
        onChange?.(e, value);
      }}
      // apply onKeyDown logic for downshift keyboard accessibility support
      onKeyDown={handleKeyDown}
      inputProps={{
        value: internalValue,
        ["aria-activedescendant"]: toggleButtonProps["aria-activedescendant"],
        ["aria-controls"]: toggleButtonProps["aria-controls"],
        ...inputProps,
      }}
      MenuContext={MenuContentContext}
      {...tokenInputProps}
    />
  );
};
