import React from "react";
import { Input, type TypeInputProps } from "@sproutsocial/seeds-react-input";
import {
  useItemFiltering,
  type TypeGetIsItemVisibleFn,
} from "../hooks/useItemFiltering";
import {
  useMenuContentContext,
  type TypeDownshiftSelectReturnValue,
} from "../MenuContext";

export interface TypeMenuSearchInputProps extends TypeInputProps {
  /* A function that can be passed to override the default filtering logic */
  getIsItemVisible?: TypeGetIsItemVisibleFn;
}

/**
 * <input
 *  aria-activedescendant=""
 *  aria-autocomplete="list"
 *  aria-controls="downshift-:r1:-menu"
 *  aria-expanded="true"
 * />
 */

/**
 * @link https://seeds.sproutsocial.com/components/menu-header/
 *
 * @description MenuSearchInput is search input that can be added to the MenuHeader to filter the items.
 */
export const MenuSearchInput = ({
  getIsItemVisible,
  inputProps,
  ...restInputProps
}: TypeMenuSearchInputProps) => {
  const {
    items,
    itemToString,
    setHiddenItemsMap,
    isOpen,
    getToggleButtonProps,
  } = useMenuContentContext();
  const { updateFilteredItems } = useItemFiltering({
    allMenuItems: items,
    itemToString,
    getIsItemVisible,
    setHiddenItemsMap,
  });

  React.useEffect(() => {
    // handles when open/close state changes or if input is controlled
    updateFilteredItems({ inputValue: restInputProps.value || "" });
  }, [isOpen, restInputProps.value]);

  const toggleButtonProps: Partial<
    ReturnType<Required<TypeDownshiftSelectReturnValue>["getToggleButtonProps"]>
  > = getToggleButtonProps?.() || {};

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      // prevents selecting items with space key when input is focused
      if (e.key === " ") return;
      toggleButtonProps.onKeyDown?.(e);
    },
    [toggleButtonProps.onKeyDown]
  );

  return (
    <Input
      onChange={(e, value) => {
        updateFilteredItems({ inputValue: value });
      }}
      // apply onKeyDown logic for downshift keyboard accessibility support
      onKeyDown={handleKeyDown}
      autoComplete={false}
      aria-autocomplete="list"
      inputProps={{
        ["aria-activedescendant"]: toggleButtonProps["aria-activedescendant"],
        ["aria-controls"]: toggleButtonProps["aria-controls"],
        ...inputProps,
      }}
      {...restInputProps}
    />
  );
};
