import React, { type ReactNode } from "react";
import { Button, type TypeButtonProps } from "@sproutsocial/seeds-react-button";
import { useMenuToggleContext } from "../MenuContext";

export interface TypeMenuToggleButtonProps extends Partial<TypeButtonProps> {
  children: ReactNode;
}

export const MenuToggleButton = ({
  children,
  ...rest
}: TypeMenuToggleButtonProps) => {
  const { getToggleButtonProps, isListbox, getDropdownProps, ref, ariaProps } =
    useMenuToggleContext();
  return (
    <Button
      appearance="secondary"
      {...getToggleButtonProps?.({
        // Decide if we want the preventKeyAction stuff. Maybe create a prop to allow for this to be overriden.
        ...(getDropdownProps?.({ ref, preventKeyAction: true }) || { ref }),
        refKey: "innerRef",
        ...ariaProps,
        ...(isListbox ? {} : { role: "button", "aria-haspopup": "menu" }),
      })}
      {...rest}
    >
      {children}
    </Button>
  );
};
