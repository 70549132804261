import React from "react";
import type { TypeMenuContext } from "../MenuContext";
import type { TypeInternalItemProps } from "../types";
import { itemToString as defaultItemToString } from "../utils/downshiftDefaults";

export type TypeGetIsItemVisibleFn = (
  item: TypeMenuContext["items"][number],
  inputValue: string
) => boolean;

/**
 * @description A function to get the default getIsItemVisible function
 *
 * @param itemToString - A function to convert an item to a string
 *
 * @returns The default getIsItemVisible function
 */
export const getDefaultGetIsItemVisible =
  (
    itemToString: TypeMenuContext["itemToString"] = defaultItemToString
  ): TypeGetIsItemVisibleFn =>
  (item, inputValue) =>
    itemToString(item).toLowerCase().includes(inputValue.toLowerCase());

export type TypeGetShouldFilterFn = (arg: { inputValue?: string }) => boolean;

export const defaultGetShouldFilter: TypeGetShouldFilterFn = ({ inputValue }) =>
  Boolean(inputValue);

export interface TypeItemFilteringProps {
  allMenuItems: TypeInternalItemProps[];
  itemToString?: TypeMenuContext["itemToString"];
  getIsItemVisible?: TypeGetIsItemVisibleFn;
  getShouldFilter?: TypeGetShouldFilterFn;
  setHiddenItemsMap?: TypeMenuContext["setHiddenItemsMap"];
}

export const useItemFiltering = ({
  allMenuItems,
  itemToString,
  getIsItemVisible = getDefaultGetIsItemVisible(itemToString),
  getShouldFilter = defaultGetShouldFilter,
  setHiddenItemsMap: setHiddenItemsMapProp,
}: TypeItemFilteringProps) => {
  const [hiddenItemsMap, setHiddenItemsMap] = React.useState<
    Required<TypeMenuContext>["hiddenItemsMap"]
  >({});

  const updateFilteredItems = React.useCallback(
    ({ inputValue }) => {
      const newHiddenItems = getShouldFilter({ inputValue })
        ? allMenuItems.reduce((map, item) => {
            const isItemVisible = getIsItemVisible(item, inputValue);
            return isItemVisible ? map : { ...map, [item.id]: true };
          }, {} as typeof hiddenItemsMap)
        : {};

      setHiddenItemsMap(newHiddenItems);
      setHiddenItemsMapProp?.(newHiddenItems);
    },
    [allMenuItems, getIsItemVisible, getShouldFilter]
  );

  return {
    getIsItemVisible,
    updateFilteredItems,
  };
};
