import styled, { css } from "styled-components";
import {
  COMMON,
  LAYOUT,
  FLEXBOX,
  GRID,
  TYPOGRAPHY,
} from "@sproutsocial/seeds-react-system-props";
import type { TypeTextProps } from "./TextTypes";

const Container = styled.span<TypeTextProps>`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  font-family: ${(props) => props.theme.fontFamily};
  font-style: ${(props) => props.isItalicized && "italic"};

  ${(props) =>
    props.truncated &&
    css`
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.breakWord &&
    css`
      word-break: break-word;
      hyphens: auto;
    `}

  ${COMMON}
  ${LAYOUT}
  ${FLEXBOX}
  ${GRID}
  ${TYPOGRAPHY}
`;

export default Container;
