import type { TypeDownshiftMultiSelectProps } from "../MenuContext";

export const getMultiSelectOverrides = ({
  getA11yMultiStatusMessage,
  onMultiSelectStateChange,
  multiSelectStateReducer,
  ...useSelectProps
}: TypeDownshiftMultiSelectProps) =>
  Object.entries({
    ...useSelectProps,
    getA11yStatusMessage: getA11yMultiStatusMessage,
    onStateChange: onMultiSelectStateChange,
    stateReducer: multiSelectStateReducer,
  }).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {} as {
      getA11yStatusMessage?: TypeDownshiftMultiSelectProps["getA11yMultiStatusMessage"];
      onStateChange?: TypeDownshiftMultiSelectProps["onMultiSelectStateChange"];
      stateReducer?: TypeDownshiftMultiSelectProps["multiSelectStateReducer"];
    }
  );
