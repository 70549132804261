import React from "react";
import type {
  UseComboboxProps,
  UseSelectProps,
  UseMultipleSelectionProps,
} from "downshift";
import type { TypeInternalItemProps } from "../types";
import type {
  TypeMenuContextBaseProps,
  TypeDownshiftMultiSelectProps,
} from "../MenuContext";
import {
  useComboboxStateReducer,
  useComboboxStateReducerForMulti,
} from "../reducers/useComboboxStateReducer";
import {
  useSelectStateReducer,
  useSelectStateReducerForMulti,
} from "../reducers/useSelectStateReducer";
import { getMultiSelectOverrides } from "../utils/getMultiSelectOverrides";

export const defaultUseSelectProps: Partial<
  UseSelectProps<TypeInternalItemProps>
> = {
  stateReducer: useSelectStateReducer,
};

export const defaultUseComboboxProps: Partial<
  UseComboboxProps<TypeInternalItemProps>
> = {
  stateReducer: useComboboxStateReducer,
};

export const defaultUseSelectPropsForMulti: Partial<
  UseSelectProps<TypeInternalItemProps>
> = {
  stateReducer: useSelectStateReducerForMulti,
};

export const defaultUseComboboxPropsForMulti: Partial<
  UseComboboxProps<TypeInternalItemProps>
> = {
  stateReducer: useComboboxStateReducerForMulti,
};

export interface TypeUseDownshiftDefaultsReturn
  extends Pick<
    Required<TypeMenuContextBaseProps>,
    "hiddenItemsMap" | "setHiddenItemsMap" | "hiddenItemsCount"
  > {
  defaultDownshiftProps: {
    select: Partial<UseSelectProps<TypeInternalItemProps>>;
    combobox: Partial<UseComboboxProps<TypeInternalItemProps>>;
    multi: Partial<UseMultipleSelectionProps<TypeInternalItemProps>>;
  };
}

export const itemToKey = (item: TypeInternalItemProps | null) => item?.id;

export const useDownshiftDefaults = ({
  isCombobox = false,
  isMulti = false,
  multiSelectProps = {},
}: {
  isCombobox?: boolean;
  isMulti?: boolean;
  multiSelectProps?: TypeDownshiftMultiSelectProps;
} = {}): TypeUseDownshiftDefaultsReturn => {
  const [hiddenItemsMap, setHiddenItemsMap] = React.useState<
    Record<string, boolean>
  >({});
  const hiddenItemsCount = React.useMemo(
    () => Object.keys(hiddenItemsMap).filter(Boolean).length,
    [hiddenItemsMap]
  );
  const isItemDisabled = React.useCallback<
    Required<UseSelectProps<TypeInternalItemProps>>["isItemDisabled"]
  >(
    (item) => Boolean(item.disabled || hiddenItemsMap[item.id]),
    [hiddenItemsMap]
  );

  return {
    hiddenItemsMap,
    hiddenItemsCount,
    setHiddenItemsMap,
    defaultDownshiftProps: {
      select: !isCombobox
        ? {
            itemToKey,
            // disabling while item.hidden so that hidden items are ignored by downshift
            isItemDisabled,
            ...(isMulti
              ? defaultUseSelectPropsForMulti
              : defaultUseSelectProps),
          }
        : {},
      combobox: isCombobox
        ? {
            itemToKey,
            // disabling while item.hidden so that hidden items are ignored by downshift
            isItemDisabled,
            ...(isMulti
              ? defaultUseComboboxPropsForMulti
              : defaultUseComboboxProps),
          }
        : {},
      multi: isMulti
        ? {
            itemToKey,
            // handles the prop name mapping for multi select
            ...getMultiSelectOverrides(multiSelectProps),
          }
        : {},
    },
  };
};
