import { useMenuContentContext } from "../MenuContext";
import type { TypeInternalItemProps } from "../types";
import type { TypeMenuItemProps } from "./MenuItemTypes";
import { MenuItemContainer, StyledMenuItem } from "./styles";
import { useOptionProps } from "./useOptionProps";

// TODO: decide if we want to support inputs on menuitems
// const roles = {
//   checkbox: "menuitemcheckbox",
//   switch: "menuitemcheckbox",
//   radio: "menuitemradio",
// } as const;

const MenuItem = ({
  id,
  children,
  onClick,
  onKeyDown,
  disabled = false,
  active,
  innerRef,
  href,
  color,
  ...props
}: TypeMenuItemProps) => {
  const {
    getItemProps,
    itemsMap,
    highlightedIndex,
    isListbox,
    hiddenItemsMap = {},
  } = useMenuContentContext();
  const { ...optionProps } = useOptionProps({
    id,
    children,
    ...props,
  });
  const item = (itemsMap[id] || { index: 0, id }) as TypeInternalItemProps;

  const highlighted = highlightedIndex === item?.index;

  return hiddenItemsMap[item.id] ? (
    <></>
  ) : (
    <MenuItemContainer role="none">
      <StyledMenuItem
        id={id}
        $highlighted={highlighted}
        $active={active}
        {...getItemProps?.({
          ...(isListbox ? {} : { role: "menuitem" }),
          item,
          index: item.index,
          // The disabled prop no longer supported in downshift. Use isItemDisabled instead.
          // disabled,
          onClick,
          onKeyDown,
          ref: innerRef,
        })}
        // use anchor tag if href is provided and button for menuitem
        as={isListbox ? undefined : href ? "a" : "button"}
        href={href}
        children={children}
        // Keyboard nav uses the arrow keys, not tab.
        tabIndex={-1}
        {...optionProps}
        {...props}
      />
    </MenuItemContainer>
  );
};

MenuItem.__MENU_PRIMITIVE_TYPE = "MenuItem";

export { MenuItem };
