import { MenuItem, MenuItemActionRight } from "../MenuItem/index";
import { useNestedMenuContext } from "./NestedMenuContext";
import { Icon } from "@sproutsocial/seeds-react-icon";
import { Box } from "@sproutsocial/seeds-react-box";
import type { TypeNestedMenuItemProps } from "./NestedMenuTypes";

const NestedMenuItem = ({
  children,
  childMenuId,
  onClick: onClickProp,
  ...rest
}: TypeNestedMenuItemProps) => {
  const { setSelectedMenuId } = useNestedMenuContext();
  // This method of passing onClick is necessary because passing undefined for onClick
  // behaves differently from not passing anything at all.
  const onClickProps = childMenuId
    ? {
        onClick: (e) => {
          onClickProp?.(e);
          setSelectedMenuId?.(childMenuId);
        },
      }
    : {};

  return (
    <MenuItem {...onClickProps} {...rest}>
      <Box display="flex" justifyContent="space-between">
        {children}
        {childMenuId && (
          <MenuItemActionRight>
            <Icon name="arrow-right-outline" />
          </MenuItemActionRight>
        )}
      </Box>
    </MenuItem>
  );
};

NestedMenuItem.__MENU_PRIMITIVE_TYPE = "MenuItem";

export { NestedMenuItem };
