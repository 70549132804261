import { MenuHeader } from "../MenuHeader/index";
import { useNestedMenuContext } from "./NestedMenuContext";
import { Button } from "@sproutsocial/seeds-react-button";
import { Icon } from "@sproutsocial/seeds-react-icon";
import type { TypeNestedMenuHeaderProps } from "./NestedMenuTypes";

export const NestedMenuHeader = ({
  backArrowLabel,
  ...props
}: TypeNestedMenuHeaderProps) => {
  const { goBack, selectedMenuPath } = useNestedMenuContext();
  const showBackArrow = selectedMenuPath.length > 1;
  return (
    <MenuHeader
      leftAction={
        showBackArrow ? (
          <Button onClick={goBack} ariaLabel={backArrowLabel}>
            <Icon name="arrow-left" />
          </Button>
        ) : undefined
      }
      {...props}
    />
  );
};
