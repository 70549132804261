import React from "react";
import type { TypeMenuItemProps } from "../MenuItem";
import type { TypeMenuRootProps } from "../MenuRoot";
import type { TypeMenuGroupProps } from "./MenuGroupTypes";
import { StyledMenuGroup, StyledTitle, StyledMenuGroupUl } from "./styles";
import { mapMenuItems } from "../hooks/useMenuChildren";

/**
 * @link https://seeds.sproutsocial.com/components/menu-group/
 *
 * @description MenuGroup is used to group lists of {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/MenuItem | MenuItems}.
 *
 * @example
 * <ActionMenu>
 *   <MenuContent>
 *     <MenuGroup title="Group 1" id="1">
 *       <MenuItem>Item 1</MenuItem>
 *     </MenuGroup>
 *     <MenuGroup title="Group 2" id="2">
 *       <MenuItem>Item 2</MenuItem>
 *     </MenuGroup>
 *   </MenuContent>
 * </ActionMenu>
 *
 * @see {@link https://seeds.sproutsocial.com/components/menu-item/ | MenuItem}
 */

const MenuGroup = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  titleAs = "h3",
  children: childrenProp,
  title,
  color,
  isSingleSelect,
  id,
  menuItems,
  MenuItemComponent,
  ...rest
}: TypeMenuGroupProps<I>) => {
  // Generate a unique ID for the title
  const titleId = `menu-group-title-${Math.random().toString(36).slice(2, 11)}`;

  const children =
    childrenProp || !menuItems || !menuItems.length
      ? childrenProp
      : mapMenuItems({ menuItems, MenuItemComponent });

  if (!children) {
    return null;
  }
  return (
    <StyledMenuGroup
      role="group"
      id={id}
      isSingleSelect={isSingleSelect}
      aria-labelledby={title ? titleId : undefined}
      // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
      {...rest}
    >
      {title && (
        <StyledTitle role="heading" as={titleAs} id={titleId}>
          {title}
        </StyledTitle>
      )}
      <StyledMenuGroupUl>{children}</StyledMenuGroupUl>
    </StyledMenuGroup>
  );
};

MenuGroup.__MENU_PRIMITIVE_TYPE = "MenuGroup";

export { MenuGroup };
