import React from "react";
import type { TypeMenuFooterProps } from "./MenuFooterTypes";
import { StyledMenuFooterBox } from "./styles";

/**
 * @link https://seeds.sproutsocial.com/components/menu-footer/
 *
 * @description MenuFooter is a container used to hold footer content within a Menu.
 *
 * @see {@link https://seeds.sproutsocial.com/components/menu-header/ | MenuHeader}
 */

export const MenuFooter = ({ children, ...rest }: TypeMenuFooterProps) => {
  return <StyledMenuFooterBox {...rest}>{children}</StyledMenuFooterBox>;
};
