import styled from "styled-components";
import { motion } from "motion/react";
//import { focusRing } from "@sproutsocial/seeds-react-mixins";

export const StyledMenuContent = styled(motion.ul)`
  margin: 0;
  padding: 0;
`;

// Removing this for now because it's insanely intrusive and distracting to the user... interestingly the out of the box browser focus ring works better only appears when the content is actually focused.

// &:focus {
// 	${focusRing}
// }
