import React from "react";
import { Icon } from "@sproutsocial/seeds-react-icon";
import { Box } from "@sproutsocial/seeds-react-box";
import { mergeRefs } from "@sproutsocial/seeds-react-utilities";
import type { TypeInternalItemProps } from "../types";
import { getChildrenFromMenuItems } from "../hooks/useMenuChildren";
import { useMenuContentContext, useMenuToggleContext } from "../MenuContext";
import {
  MenuItem,
  MenuItemActionRight,
  type TypeMenuItemProps,
} from "../MenuItem";
import type {
  TypeSubmenuItemProps,
  TypeSubmenuValidMenuComponentProps,
} from "./SubmenuItemTypes";

const SubmenuItemTrigger = ({
  id,
  children,
  innerRef,
  ...rest
}: TypeMenuItemProps) => {
  const { getToggleButtonProps, getDropdownProps, ref, ariaProps } =
    useMenuToggleContext();
  const toggleRef =
    ref && innerRef
      ? mergeRefs([ref, innerRef].filter(Boolean))
      : ref || innerRef;
  return (
    <MenuItem
      {...rest}
      {...getToggleButtonProps?.(
        {
          ...(getDropdownProps?.({
            ref: toggleRef,
            preventKeyAction: true,
          }) || { ref: toggleRef }),
          refKey: "innerRef",
          role: "menuitem",
          ...ariaProps,
        },
        { suppressRefError: true }
      )}
      id={id}
    >
      <Box display="flex" justifyContent="space-between">
        {children}
        <MenuItemActionRight>
          <Icon name="chevron-right-outline" />
        </MenuItemActionRight>
      </Box>
    </MenuItem>
  );
};

const doesRefContainEventTarget = (
  ref: React.RefObject<HTMLElement | undefined>,
  event: MouseEvent
) => {
  return (
    ref.current &&
    event.target instanceof Node &&
    ref.current.contains(event.target)
  );
};

const SubmenuItem = <
  I extends TypeMenuItemProps,
  P extends TypeSubmenuValidMenuComponentProps<I>
>({
  MenuComponent,
  menuProps: {
    children: menuChildren,
    menuItems,
    MenuItemComponent,
    popoutProps: { popperProps, ...popoutProps } = {},
    onStateChange,
    ...menuProps
  },
  // isOpen,
  // onToggleSubmenu,
  id,
  children,
  ...rest
}: TypeSubmenuItemProps<I, P>) => {
  const {
    highlightedIndex,
    setHighlightedIndex,
    itemsMap,
    isOpen: parentIsOpen,
  } = useMenuContentContext();
  const [submenuIsFocused, setSubmenuIsFocused] = React.useState(false);
  const [submenuIsOpen, setSubmenuIsOpen] = React.useState(false);
  const [submenuIsActive, setSubmenuIsActive] = React.useState(false);
  const submenuItemRef = React.useRef<HTMLElement>(null);
  const submenuRef = React.useRef<HTMLDivElement>(null);

  // get children for both use cases so it can be wrapped in a submenu div
  const menuContent = menuItems
    ? getChildrenFromMenuItems({ menuItems, MenuItemComponent })
    : menuChildren;

  const item = (itemsMap[id] || {
    index: 0,
    id,
  }) as TypeInternalItemProps;

  // React.useEffect(() => {
  //   debugger;
  //   // reset isOpen on render
  //   setSubmenuIsOpen(false);
  //   setSubmenuIsActive(false);
  // }, []);

  React.useEffect(() => {
    if (highlightedIndex === item.index) {
      // Open submenu when item becomes highlighted
      !submenuIsOpen && setSubmenuIsOpen(true);
    } else if (submenuIsOpen && highlightedIndex !== -1) {
      // Close submenu when another item is highlighted
      setSubmenuIsOpen(false);
      setSubmenuIsActive(false);
    }
  }, [highlightedIndex, item.index, submenuIsOpen, setSubmenuIsOpen]);

  const onSubmenuStateChange = React.useCallback(
    (state) => {
      if (state.isOpen !== undefined) {
        // Ensures the menu opens and closes when the state changes
        setSubmenuIsOpen(state.isOpen);
        if (!state.isOpen) {
          // Reset the submenu focus and highlighted index when the submenu closes
          setSubmenuIsFocused(false);
          setHighlightedIndex?.(-1);
        }
      }
      onStateChange?.(state);
    },
    [onStateChange, setSubmenuIsOpen]
  );

  // Callback for navigating the submenu wit harrow keys
  const onSubmenuKeydown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case "ArrowRight":
      case " ":
      case "Enter":
        // Focus the submenu if item highlighted and right arrow key is pressed
        if (!submenuIsFocused && submenuIsOpen) {
          // stop propagation to avoid interacting with other components
          e.stopPropagation();
          setSubmenuIsFocused(true);
        }
        break;
      case "ArrowLeft":
        // Return focus to the parent menu if submenu is focused and left arrow key is pressed
        if (submenuIsFocused) {
          // stop propagation to avoid interacting with other components
          e.stopPropagation();
          setSubmenuIsFocused(false);
        }
        break;
      default:
        break;
    }
  };

  const onMenuItemClick = () => {
    const newIsActive = !submenuIsActive;
    // Lock the submenu in an active open state when clicked
    // or close the menu and unlock if it is open
    setSubmenuIsActive(newIsActive);
    setSubmenuIsOpen(newIsActive);
  };

  const onBodyClick = (e: MouseEvent): void => {
    // Prevent closing the parent menu when clicking on the submenu item
    if (doesRefContainEventTarget(submenuItemRef, e)) {
      e.stopPropagation();
      onMenuItemClick();
    }
    // Prevent closing other popouts when clicking on inside the submenu
    // This doesn't work because it's overridden by the logic in Popout the closes when clicked outside
    // if (doesRefContainEventTarget(submenuRef, e)) {
    //   e.stopPropagation();
    // }
  };

  React.useEffect(() => {
    if (document && document.body) {
      document.body.addEventListener("click", onBodyClick, {
        capture: true,
      });
      document.body.addEventListener("keydown", onSubmenuKeydown, {
        capture: true,
      });
      return () => {
        document.body.removeEventListener("click", onBodyClick, {
          capture: true,
        });
        document.body.removeEventListener("keydown", onSubmenuKeydown, {
          capture: true,
        });
      };
    }
  }, [document, onSubmenuKeydown]);

  const subMenuContent = (
    <div
      ref={submenuRef}
      onKeyDown={(e) => {
        // prevent keydown on submenu triggering the parent menu
        e.stopPropagation();
      }}
      tabIndex={-1}
    >
      {menuContent}
    </div>
  );

  return (
    <MenuComponent
      {...({
        ...menuProps,
        children: subMenuContent,
        menuToggleElement: (
          <SubmenuItemTrigger
            innerRef={submenuItemRef}
            id={id}
            children={children}
            active={submenuIsActive}
            {...rest}
          />
        ),
        isOpen: submenuIsOpen,
        onStateChange: onSubmenuStateChange,
        popoutProps: {
          placement: "right",
          popperProps: {
            modifiers: {
              offset: {
                offset: "0,18",
              },
            },
            ...popperProps,
          },
          focusOnContent: submenuIsFocused,
          ...popoutProps,
        },
      } as P)}
    />
  );
};

SubmenuItem.__MENU_PRIMITIVE_TYPE = "MenuItem";

export { SubmenuItem };
