import { MenuContent, type TypeMenuContentProps } from "../MenuContent/index";
import { useMenuContentContext } from "../MenuContext";
import type { TypeChildrenOrItems, TypeInternalItemProps } from "../types";
import { useNestedMenuContext } from "./NestedMenuContext";
import type { TypeMenuItemProps } from "../MenuItem/index";
import type { TypeNestedMenuItemProps } from "./NestedMenuTypes";
import { useEffect, useRef } from "react";
import { mergeRefs } from "@sproutsocial/seeds-react-utilities";
import { NestedMenuItem } from "./NestedMenuItem";

const NestedMenuContent = <
  I extends TypeMenuItemProps = TypeNestedMenuItemProps
>({
  innerRef: innerRefProp = null,
  onKeyDown: onKeyDownProp,
  children,
  menuItems,
  MenuItemComponent = NestedMenuItem,
  ...props
}: TypeMenuContentProps<I>) => {
  const { highlightedIndex, items } = useMenuContentContext();
  const { setSelectedMenuId, selectedMenuPath, goBack } =
    useNestedMenuContext();
  const internalRef = useRef<HTMLUListElement>(null);
  // Merge our ref and the consumer's ref so they will both be updated
  const innerRef = mergeRefs<HTMLUListElement>(internalRef, innerRefProp);

  const contentProps = children
    ? { children }
    : ({ menuItems, MenuItemComponent } as TypeChildrenOrItems<I>);

  // Focus the content on mount so that keyboard nav is available immediately
  useEffect(() => {
    internalRef?.current?.focus();
  }, []);

  const onKeyDown = (e) => {
    // Call the consumer's onKeyDown handler first
    onKeyDownProp?.(e);

    // Handle navigating into and out of child menus
    switch (e.key) {
      case "ArrowRight":
      case " ":
      case "Enter":
        if (highlightedIndex !== undefined && highlightedIndex !== -1) {
          const item = items[
            highlightedIndex
          ] as TypeInternalItemProps<TypeNestedMenuItemProps>;
          if (item?.menuItemProps?.childMenuId !== undefined) {
            setSelectedMenuId?.(item?.menuItemProps?.childMenuId);
          }
        }
        break;
      case "ArrowLeft":
        if (selectedMenuPath.length > 1) {
          goBack?.();
        }
        break;
    }
  };

  return (
    <MenuContent
      onKeyDown={onKeyDown}
      innerRef={innerRef}
      {...contentProps}
      {...props}
    />
  );
};

NestedMenuContent.__MENU_PRIMITIVE_TYPE = "MenuContent";

export { NestedMenuContent };
