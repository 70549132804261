import React, { createContext, useContext } from "react";
import type { TypeNotEmptyChildren } from "../types";

export interface TypeNestedMenuContext {
  selectedMenuPath: string[];
  setSelectedMenuId?: (id: string) => void;
  setSelectedMenuPath?: (path: string[]) => void;
  goBack?: () => void;
}

const defaultNestedMenuContext: TypeNestedMenuContext = {
  selectedMenuPath: [],
  setSelectedMenuId: () => {},
  setSelectedMenuPath: () => {},
  goBack: () => {},
};

export interface TypeNestedMenuProviderProps extends TypeNestedMenuContext {
  children: TypeNotEmptyChildren;
}

/**
 * NestedMenuContext factory, sets the type and the initial object
 */
export const NestedMenuContext = createContext<TypeNestedMenuContext>(
  defaultNestedMenuContext
);

/**
 * Provider Component sets the context values in the provider
 */
export const NestedMenuProvider = ({
  children,
  ...nestedMenuProps
}: TypeNestedMenuProviderProps) => {
  return (
    <NestedMenuContext.Provider value={nestedMenuProps}>
      {children}
    </NestedMenuContext.Provider>
  );
};

/**
 * Utility hook for consuming NestedMenuContext
 */
export const useNestedMenuContext = (): TypeNestedMenuContext => {
  const nestedMenuContextValue = useContext(NestedMenuContext);

  if (nestedMenuContextValue === null) {
    throw new Error(
      "useNestedMenuContext must be used within a <NestedMenuContext.Provider />"
    );
  }

  return nestedMenuContextValue;
};
