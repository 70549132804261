import styled from "styled-components";
import Text from "@sproutsocial/seeds-react-text";
import {
  border,
  layout,
  position,
  flexbox,
  grid,
  color,
  space,
} from "styled-system";
import type { TypeMenuGroupBaseProps } from "./MenuGroupTypes";

export const StyledMenuGroup = styled.li<TypeMenuGroupBaseProps>`
  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.container.border.base};
  }
  list-style: none;
  margin: 0;

  ${border}
  ${position}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${space}
`;

export const StyledTitle = styled(Text.SmallSubHeadline)`
  font-size: ${({ theme }) => theme.typography[100].fontSize};
  margin: ${({ theme }) => theme.space[400]} ${({ theme }) => theme.space[400]}
    0px ${({ theme }) => theme.space[400]};
`;

export const StyledMenuGroupUl = styled.ul`
  margin: 0;
  padding: 0;
`;
