import React, { useState } from "react";
import type { TypeMenuItemProps } from "../MenuItem";
import type {
  TypeNestedMenuItemProps,
  TypeNestedMenuProps,
} from "./NestedMenuTypes";
import { reduceReducers } from "../utils/reduceReducers";
import { NestedMenuHeader } from "./NestedMenuHeader";
import { nestedMenuStateReducer } from "../reducers/nestedMenuStateReducer";
import { NestedMenuContent } from "./NestedMenuContent";
import { NestedMenuProvider } from "./NestedMenuContext";
import { NestedMenuItem } from "./NestedMenuItem";

export const NestedMenu = <
  I extends TypeMenuItemProps = TypeNestedMenuItemProps
>({
  initialMenuId,
  menus,
  onBackButtonClick,
  backArrowLabel,
  menuToggleElement,
}: TypeNestedMenuProps<I>) => {
  const [selectedMenuPath, setSelectedMenuPath] = useState([initialMenuId]);
  const selectedMenuId = selectedMenuPath[selectedMenuPath.length - 1];

  const setSelectedMenuId = (id: string) => {
    setSelectedMenuPath((currentPath) => [...currentPath, id]);
  };
  const goBack = () => {
    setSelectedMenuPath((currentPath) => currentPath.slice(0, -1));
    onBackButtonClick?.(selectedMenuId);
  };

  const {
    MenuComponent,
    menuProps,
    MenuHeaderComponent = NestedMenuHeader,
    menuHeaderProps = {},
  } = menus[selectedMenuId] || {};
  const {
    children,
    menuItems,
    MenuItemComponent,
    stateReducer: externalStateReducer,
    onStateChange: externalOnStateChange,
    ...props
  } = menuProps || {};

  const stateReducer = reduceReducers(
    nestedMenuStateReducer,
    externalStateReducer
  );

  // Reactively reset the selected menu path when the menu closes
  const onStateChange = (changes) => {
    externalOnStateChange?.(changes);
    if (changes.isOpen === false) {
      // Wait for the menu to close before resetting to avoid flicker
      setTimeout(() => {
        setSelectedMenuPath([initialMenuId]);
      }, 100);
    }
  };

  const nestedMenuProps = {
    menuToggleElement,
    stateReducer,
    onStateChange,
    initialIsOpen: selectedMenuPath.length > 1,
    children: (
      <>
        {/*This header can be suppressed by passing `null` for MenuHeaderComponent*/}
        {MenuHeaderComponent && (
          <MenuHeaderComponent
            backArrowLabel={backArrowLabel}
            {...menuHeaderProps}
          />
        )}
        {/*children takes precedence over menuItems if both are passed.*/}
        {children && children}
        {menuItems && !children && (
          <NestedMenuContent
            menuItems={menuItems}
            MenuItemComponent={MenuItemComponent ?? NestedMenuItem}
          />
        )}
      </>
    ),
    ...props,
  };

  return (
    <NestedMenuProvider
      selectedMenuPath={selectedMenuPath}
      setSelectedMenuPath={setSelectedMenuPath}
      setSelectedMenuId={setSelectedMenuId}
      goBack={goBack}
    >
      {MenuComponent && <MenuComponent {...nestedMenuProps} />}
    </NestedMenuProvider>
  );
};
