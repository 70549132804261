import styled from "styled-components";
import {
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
} from "styled-system";
import type { TypeMenuHeaderProps } from "./MenuHeaderTypes";

interface TypeMenuHeaderContainerProps extends TypeMenuHeaderProps {
  $leftAction?: boolean;
}

export const MenuHeaderContainer = styled.div<TypeMenuHeaderContainerProps>`
  border-color: ${({ theme }) => theme.colors.container.border.base};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  box-sizing: border-box;
  width: 100%;
  font-size: ${({ theme }) => theme.typography[200].fontSize};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[350]} ${({ theme }) => theme.space[400]};

  ${border}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${space}
  ${position}
`;

export const MenuTitleText = styled.h3<TypeMenuHeaderContainerProps>`
  color: ${({ theme }) => theme.colors.text.headline};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0px;
  flex-grow: 1;

  ${({ theme }) => theme.typography[200]}

  ${({ $leftAction, theme }) =>
    $leftAction &&
    `
      padding-left: ${theme.space[350]};
    `}
`;

export const MenuChildrenContainer = styled.div`
  grid-column: 1 / -1;
  margin-top: ${({ theme }) => theme.space[300]};

  &:first-child {
    margin-top: 0;
  }
`;

export const MenuHeaderFeatures = styled.div<TypeMenuHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .right-action {
    margin-left: auto; /* Pushes the right action to the right */
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0; /* Prevents it from shrinking */
    max-width: 40%; /* Prevents it from taking too much space */
    text-align: right;
  }

  > a,
  > button {
    display: flex;
    align-items: center;
    word-break: break-word;
  }
`;
