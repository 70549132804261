import { useStaticQuery, graphql } from "gatsby";

// TODO: Refactor this to dynamically load props for typescript components
// const useComponentProps = (componentName) => {
//   const {
//     component: { nodes },
//   } = useStaticQuery(query)
//   const allComponents = nodes.map((node) => node.metadata).flat()
//   const data = allComponents.find(
//     (component) =>
//       component.displayName.toLowerCase() === componentName.toLowerCase()
//   )
//   const componentProps = data ? data.props : null
//   debugger
//   return componentProps
// }

// export const query = graphql`
//   query ComponentProps {
//     component: allFile(
//       filter: {
//         sourceInstanceName: { eq: "racine" }
//         relativePath: { regex: "/^.+/index.js$/" }
//       }
//     ) {
//       nodes {
//         metadata: childrenComponentMetadata {
//           displayName
//           props {
//             name
//             description {
//               text
//             }
//             defaultValue {
//               value
//             }
//             required
//             type: flowType
//           }
//         }
//       }
//     }
//   }
// `

// TODO: Remove this once dynamic typescript props are working
const useComponentProps = (componentName) => {
  const {
    allComponents: { nodes: allComponentProps },
  } = useStaticQuery(query);
  const data = allComponentProps.find(
    (component) =>
      component?.displayName?.toLowerCase() === componentName?.toLowerCase()
  );
  const componentProps = data ? data.props : null;
  return componentProps;
};

export const query = graphql`
  query ComponentProps {
    allComponents: allComponentProps {
      nodes {
        displayName
        props {
          name
          description
          defaultValue {
            value
          }
          required
          type: tsType {
            name
            raw
            elements {
              name
              value
              raw
            }
          }
        }
      }
    }
  }
`;

export default useComponentProps;
