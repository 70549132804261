import React from "react";
import styled from "styled-components";
import {
  TokenInput,
  type TypeTokenInputProps,
  type TypeTokenSpec,
} from "@sproutsocial/seeds-react-token-input";
import type { TypeInternalItemProps } from "./types";
import { MenuContentContext, MenuToggleContext } from "./MenuContext";

const StyledTokenInputWrapper = styled.div`
  > div {
    padding: 4px;
  }

  input {
    line-height: 21px;
    margin: 0;
    padding: 2px 4px;
  }

  .TokenInput-token button {
    margin-top: 0;
    padding: 1px 4px;
  }
`;

export interface TypeMenuTokenInputProps extends TypeTokenInputProps {
  /* A function that can be passed to override the default token props */
  getTokenProps?: (
    itemId: TypeInternalItemProps["id"]
  ) => Partial<TypeTokenSpec>;
  MenuContext?: typeof MenuToggleContext | typeof MenuContentContext;
}

export const MenuTokenInput = ({
  inputProps = {},
  getTokenProps,
  onKeyDown,
  MenuContext = MenuToggleContext,
  ...tokenInputProps
}: TypeMenuTokenInputProps) => {
  const {
    itemToString,
    getSelectedItemProps,
    removeSelectedItem,
    setActiveIndex,
    selectedItems = [],
  } = React.useContext(MenuContext);

  const removeToken = (tokenId: string) => {
    const tokenToRemove = selectedItems?.find((item) => item.id === tokenId);
    tokenToRemove && removeSelectedItem?.(tokenToRemove);
  };

  return (
    <StyledTokenInputWrapper>
      <TokenInput
        onKeyDown={(e, value) => {
          // set active index to last item when arrow left is pressed and input is empty
          if (e.key === "ArrowLeft" && !value && selectedItems.length) {
            setActiveIndex?.(selectedItems.length - 1);
          }
          onKeyDown?.(e, value);
        }}
        inputProps={{
          autoComplete: "off",
          type: "search",
          ...inputProps,
        }}
        tokens={selectedItems.map((item, index) => {
          const {
            tokenProps: { ref, onKeyDown, ...restTokenProps } = {
              ref: undefined,
              onKeyDown: undefined,
            },
            ...rest
          } = getTokenProps?.(item.id) || {};
          return {
            id: item.id,
            value: itemToString?.(item) || item.id,
            tokenProps: {
              ...getSelectedItemProps?.({
                selectedItem: item,
                index,
                ref,
                refKey: "innerRef",
                onKeyDown,
              }),
              // nullify the onclick provided from downshift
              // TokenInput deletes the token on click
              onClick: undefined,
              tabIndex: 0,
              ...restTokenProps,
            },
            ...rest,
          };
        })}
        onRemoveToken={removeToken}
        {...tokenInputProps}
      />
    </StyledTokenInputWrapper>
  );
};
