import React, { useContext, useMemo } from "react";
import { Icon } from "@sproutsocial/seeds-react-icon";
import { Checkbox } from "@sproutsocial/seeds-react-checkbox";
import { Switch } from "@sproutsocial/seeds-react-switch";
import { Radio } from "@sproutsocial/seeds-react-radio";
import { useTheme } from "styled-components";
import { Box } from "@sproutsocial/seeds-react-box";
import { useMenuContentContext } from "../MenuContext";

import type { TypeMenuItemProps, InputType } from "./MenuItemTypes";
import { MenuItemActionLeft, MenuItemRow, MenuItemText } from "./styles";

/**
 * SelectionIndicator - handles logic for appropriate rendering of inputs
 * @returns Checkbox | Icon | Radio | Switch
 */
export const SelectionIndicator = ({
  id,
  inputType = "icon",
  "aria-labelledby": labeledBy,
  selected,
}: {
  id: string;
  "aria-labelledby": string;
  selected: boolean;
  inputType: InputType;
}) => {
  const { space } = useTheme();

  const inputStub = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  switch (inputType) {
    case "checkbox":
      return (
        <Checkbox
          tabIndex={-1}
          onChange={() => {}}
          checked={selected}
          aria-labelledby={labeledBy}
          id={`MenuItem-${inputType}-${id}`}
        />
      );
    case "switch":
      return (
        <Switch
          tabIndex={-1}
          onClick={inputStub}
          checked={!!selected}
          aria-labelledby={labeledBy}
          id={`MenuItem-${inputType}-${id}`}
        />
      );
    case "radio":
      return (
        <Radio
          tabIndex={-1}
          checked={selected}
          id={`MenuItem-${inputType}-${id}`}
          name={`MenuItem-${inputType}`}
          aria-labelledby={labeledBy}
        />
      );
    case "icon":
      if (selected) {
        return <Icon name="check-solid" aria-hidden size="small" />;
      } else {
        return <Box width={space["400"]}></Box>;
      }
    default:
      return null;
  }
};

/**
 * useOptionProps - props needed for the option menu items
 */
export const useOptionProps = ({
  id,
  children,
  inputType = "icon",
  inputLabelId,
  ...props
}: TypeMenuItemProps) => {
  const { selectedItem, selectedItemIds, isListbox, isItemSelected } =
    useMenuContentContext();
  const selected = useMemo(
    () => isItemSelected(id),
    [selectedItem?.id, selectedItemIds]
  );

  const label = inputLabelId || `menu-item-child-${id}`;

  return isListbox
    ? {
        active: selected,
        ["aria-selected"]: selected,
        children: (
          <MenuItemRow>
            <MenuItemActionLeft>
              <SelectionIndicator
                id={id}
                aria-labelledby={label}
                inputType={inputType}
                selected={!!selected}
              />
            </MenuItemActionLeft>
            <MenuItemText id={label}>{children}</MenuItemText>
          </MenuItemRow>
        ),
        ...props,
      }
    : {};
};
