import React, { useEffect } from "react";
import {
  useSelect,
  type UseSelectState,
  type UseSelectStateChange,
} from "downshift";
import type { TypeInternalItemProps } from "../types";
import { useMenuChildren } from "../hooks/useMenuChildren";
import { useDownshiftDefaults } from "../hooks/useDownshiftDefaults";
import { reduceReducers } from "../utils/reduceReducers";
import { itemToString } from "../utils/downshiftDefaults";
import { MenuRoot, type TypeMenuRootProps } from "../MenuRoot";
import type { TypeMenuItemProps } from "../MenuItem";

import type { TypeActionMenuProps } from "./ActionMenuTypes";

// Manually fire the onClick handler when Enter or Space is pressed
const handleStateChange = (
  changes: UseSelectStateChange<TypeInternalItemProps>,
  currentSelectedItem: UseSelectState<TypeInternalItemProps>["selectedItem"]
) => {
  // if not Enter or Space key, do nothing
  if (
    !(
      changes.type === useSelect.stateChangeTypes.ToggleButtonKeyDownEnter ||
      changes.type === useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton
    )
  ) {
    return;
  }

  const selectedItem = changes.selectedItem || currentSelectedItem;

  // if selectedItem empty, do nothing
  if (!selectedItem) return;

  // if selectedItem is disabled, do nothing
  if (selectedItem.disabled) {
    return;
  }

  // Determine if Enter key was pressed
  const isEnterKeyDown =
    changes.type === useSelect.stateChangeTypes.ToggleButtonKeyDownEnter;

  // selectedItem props
  const selectedItemProps = selectedItem.menuItemProps;

  if (selectedItemProps?.onClick) {
    // Create a synthetic event to pass into the onClick
    const syntheticKeyboardEvent = new KeyboardEvent("keydown", {
      key: isEnterKeyDown ? "Enter" : " ",
      code: isEnterKeyDown ? "Enter" : "Space",
      bubbles: true,
      cancelable: true,
      view: window,
    });

    // Manually call the onClick handler with a simulated event
    // Cast it to React.MouseEvent to match onClick expectations
    selectedItemProps?.onClick?.(
      syntheticKeyboardEvent as unknown as React.MouseEvent<HTMLElement>
    );
  }

  if (selectedItemProps?.href) {
    if (selectedItemProps.target) {
      window.open(selectedItemProps.href, selectedItemProps.target);
    } else {
      window.location.href = selectedItemProps?.href;
    }
  }
};

/**
 * @link https://seeds.sproutsocial.com/components/action-menu/
 *
 * @description An ActionMenu contains a list of clickable items like buttons or links. ActionMenus do not hold a selection.
 *
 * **To select menu items:**
 * @see {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/SingleSelectMenu | SingleSelectMenu}
 * @see {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/MultiSelectMenu | MultiSelectMenu}
 */
export const ActionMenu = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  children: childrenProp,
  menuItems,
  MenuItemComponent,
  stateReducer: externalStateReducer,
  ...useSelectProps
}: TypeActionMenuProps<I>) => {
  const { allMenuItems, menuItemsMap, children } = useMenuChildren({
    children: childrenProp,
    menuItems,
    MenuItemComponent,
  });
  const { defaultDownshiftProps, ...restDefaults } = useDownshiftDefaults({
    isCombobox: false,
  });
  const [currentSelectedItem, setCurrentSelectedItem] =
    React.useState<UseSelectState<TypeInternalItemProps>["selectedItem"]>(null);

  const { isOpen, ...useSelectReturnProps } = useSelect({
    ...defaultDownshiftProps.select,
    items: allMenuItems,
    itemToString,
    onStateChange: (changes) => handleStateChange(changes, currentSelectedItem),
    stateReducer: reduceReducers(
      defaultDownshiftProps.select.stateReducer,
      externalStateReducer
    ),
    ...useSelectProps,
  });

  useEffect(() => {
    setCurrentSelectedItem(useSelectReturnProps.selectedItem);
  }, [useSelectReturnProps.selectedItem]);

  return (
    <MenuRoot
      {...{
        isListbox: false,
        items: allMenuItems,
        itemsMap: menuItemsMap,
        itemToString,
        isOpen,
        ...useSelectProps,
        ...useSelectReturnProps,
        ...restDefaults,
      }}
    >
      {children}
    </MenuRoot>
  );
};

export default ActionMenu;
