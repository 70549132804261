import { theme } from "@sproutsocial/seeds-react-theme";

import type { TypeResponsive } from "@sproutsocial/seeds-react-system-props";

const breakpoints = theme.breakpoints;
const LENGTH = breakpoints.length + 1;

export const normalizeResponsiveProp = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: TypeResponsive<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): TypeResponsive<any> => {
  if (["string", "number"].includes(typeof value)) {
    return Array(LENGTH).fill(value);
  }

  if (value.length) {
    switch (value.length) {
      case 1:
        return Array(LENGTH).fill(value[0]);
      case 2:
        return [value[0], ...Array(LENGTH - 1).fill(value[1])];
      case 3:
        return [value[0], value[1], ...Array(LENGTH - 2).fill(value[2])];
      case 4:
        return [
          value[0],
          value[1],
          value[2],
          ...Array(LENGTH - 3).fill(value[3]),
        ];
      case 5:
        return value;
      default:
        throw new Error(
          `Invalid responsive prop length: ${JSON.stringify(value)}`
        );
    }
  } else {
    throw new Error(`Invalid responsive prop type: ${JSON.stringify(value)}`);
  }
};
