import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Popout, type TypePopoutProps } from "@sproutsocial/seeds-react-popout";

import {
  MenuToggleProvider,
  MenuContentProvider,
  useMenu,
  type TypeMenuContextProps,
  type TypeMenuProviderProps,
} from "../MenuContext";

export interface TypeMenuRootOwnProps {
  menuToggleElement: React.ReactElement<any>;
  popoutProps?: Partial<
    Omit<TypePopoutProps, "isOpen" | "setIsOpen" | "content" | "children">
  >;
  width?: TypePopoutProps["width"];
}
export interface TypeMenuRootProps
  extends Partial<TypeMenuContextProps>,
    Pick<TypeMenuProviderProps, "children">,
    TypeMenuRootOwnProps {}

const CustomPopoutContent = styled(Popout.Content)`
  padding: 0;
  margin-left: 0;
  margin-right: 0;
`;

/**
 * @link https://seeds.sproutsocial.com/components/menu-root/
 *
 * @description MenuRoot is a utility component used to handle {@link https://github.com/sproutsocial/seeds/blob/dev/seeds-react/seeds-react-menu/src/MenuContext.tsx | MenuContext}. This component should rarely, if ever, be used directly unless building a custom menu type.
 */

export const MenuRoot = ({
  children,
  menuToggleElement,
  popoutProps: { placement = "bottom", focusLockProps, ...popoutProps } = {},
  width = "300px",
  ...contextProps
}: TypeMenuRootProps) => {
  const {
    isOpen,
    openMenu,
    closeMenu,
    inputValue,
    hiddenItemsCount = 0,
    items,
  } = contextProps;
  const setIsOpen = useCallback(
    (newIsOpen: boolean) => {
      newIsOpen ? openMenu?.() : closeMenu?.();
    },
    [openMenu, closeMenu]
  );

  useEffect(() => {
    if (!inputValue) return;

    if (items && hiddenItemsCount >= items.length && isOpen) {
      closeMenu?.();
    }
  }, [hiddenItemsCount, items?.length, isOpen, inputValue]);

  const menuContext = useMenu(contextProps);

  return (
    <Popout
      menuPopout
      placement={placement}
      isOpen={!!isOpen}
      setIsOpen={setIsOpen}
      content={
        <MenuContentProvider menuContext={menuContext}>
          <CustomPopoutContent width={width}>{children}</CustomPopoutContent>
        </MenuContentProvider>
      }
      focusLockProps={{
        // correct jerking motion when scrolling while the Popout is open
        // returnFocus: false,
        crossFrame: false,
        ...focusLockProps,
      }}
      disableWrapperAria
      {...popoutProps}
    >
      {(toggleProps) => (
        <MenuToggleProvider menuContext={{ ...menuContext, ...toggleProps }}>
          {menuToggleElement}
        </MenuToggleProvider>
      )}
    </Popout>
  );
};
