import { useCombobox } from "downshift";
import type { UseComboboxProps } from "downshift";
import type { TypeInternalItemProps } from "../types";
import { reduceReducers } from "../utils/reduceReducers";

export const useComboboxStateReducer: Required<
  UseComboboxProps<TypeInternalItemProps>
>["stateReducer"] = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;

  switch (type) {
    /** If downshift is blurred by tabbing within the menu, stay open */
    case useCombobox.stateChangeTypes.InputBlur:
      return {
        ...changes,
        // keep menu open on blur
        isOpen: true,
      };

    /** Return standard change payload */
    default:
      return changes;
  }
};

export const useComboboxStateReducerForMulti: Required<
  UseComboboxProps<TypeInternalItemProps>
>["stateReducer"] = reduceReducers(
  useComboboxStateReducer,
  (state, actionAndChanges) => {
    const { changes, type } = actionAndChanges;

    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...changes,
          ...(changes.selectedItem && {
            inputValue: "",
          }),
        };
      default:
        return changes;
    }
  }
);
