import { useContext } from "react";
import { Input, type TypeInputProps } from "@sproutsocial/seeds-react-input";
import type { TypeDownshiftMergedInputProps } from "../types";
import { useMenuToggleContext } from "../MenuContext";

export interface TypeAutocompleteInputProps
  extends TypeDownshiftMergedInputProps<TypeInputProps> {}

export const AutocompleteInput = ({
  onKeyDown,
  onChange,
  onInput,
  onBlur,
  onClick,
  inputProps,
  ...rest
}: TypeAutocompleteInputProps) => {
  const { getInputComponentProps, inputValue, ref, ariaProps } =
    useMenuToggleContext();

  return (
    <Input
      id="autocomplete_input"
      name="autocomplete_input"
      {...getInputComponentProps({
        onKeyDown,
        onChange,
        onInput,
        onBlur,
        onClick,
        value: inputValue,
        ref,
        refKey: "innerRef",
        inputProps,
        ...ariaProps,
      })}
      autoComplete={false}
      value={inputValue}
      {...rest}
    />
  );
};
