import { useSelect } from "downshift";
import type { UseSelectProps } from "downshift";
import type { TypeInternalItemProps } from "../types";
import { reduceReducers } from "../utils/reduceReducers";

export const useSelectStateReducer: Required<
  UseSelectProps<TypeInternalItemProps>
>["stateReducer"] = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;
  switch (type) {
    /** If downshift is blurred by tabbing within the menu, stay open */
    case useSelect.stateChangeTypes.ToggleButtonBlur:
      return {
        ...changes,
        // keep menu open on blur
        isOpen: true,
      };

    /** Return standard change payload */
    default:
      return changes;
  }
};

export const useSelectStateReducerForMulti: Required<
  UseSelectProps<TypeInternalItemProps>
>["stateReducer"] = reduceReducers(
  useSelectStateReducer,
  (state, actionAndChanges) => {
    const { changes, type } = actionAndChanges;
    switch (type) {
      case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
      case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true, // keep the menu open after selection.
          // TODO: Confirm that keeping highlightedIndex the same is the desired UX
          // highlightedIndex: 0, // with the first option highlighted.
          highlightedIndex: state.highlightedIndex, // keep highlightedIndex the same instead of returning to 0
        };
    }
    return changes;
  }
);
