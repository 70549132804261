import type { TypeMenuContentProps } from "./MenuContentTypes";
import { StyledMenuContent } from "./styles";
import { useMenuContentContext } from "../MenuContext";
import { mapMenuItems } from "../hooks/useMenuChildren";
import { useCallback } from "react";
import type { TypeMenuItemProps } from "../MenuItem";

/**
 * @link https://seeds.sproutsocial.com/components/menu-content/
 *
 * @description MenuContent is used to contain lists or grouped lists of {@link https://github.com/sproutsocial/seeds/tree/dev/seeds-react/seeds-react-menu/src/MenuItem | MenuItems}.
 *
 * @example
 * <ActionMenu>
 *   <MenuHeader>Header</MenuHeader>
 *   <MenuContent>
 *     <MenuGroup id="1">
 *       <MenuItem>Item 1</MenuItem>
 *     </MenuGroup>
 *     <MenuGroup id="2">
 *       <MenuItem>Item 2</MenuItem>
 *     </MenuGroup>
 *   </MenuContent>
 *   <MenuFooter>Footer</MenuFooter>
 * </ActionMenu>
 *
 * @see {@link https://seeds.sproutsocial.com/components/menu-item/ | MenuItem}
 * @see {@link https://seeds.sproutsocial.com/components/menu-group/ | MenuGroup}
 */

const MenuContent = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  id = "menu_content",
  children: childrenProp,
  menuItems,
  MenuItemComponent,
  onKeyDown,
  innerRef = null,
  ...rest
}: TypeMenuContentProps<I>) => {
  const { getToggleButtonProps, getMenuProps, isListbox } =
    useMenuContentContext();
  const toggleButtonProps = getToggleButtonProps?.();

  const children =
    childrenProp || !menuItems || !menuItems.length
      ? childrenProp
      : mapMenuItems({ menuItems, MenuItemComponent });

  if (!children) {
    return null;
  }

  // Memoize the onKeyDown handler to prevent unnecessary re-renders
  const handleKeyDown = useCallback(
    (e) => {
      toggleButtonProps?.["onKeyDown"]?.(e);
      onKeyDown?.(e);
    },
    [onKeyDown, toggleButtonProps?.["onKeyDown"]]
  );

  return (
    <StyledMenuContent
      autoFocus
      id={id}
      tabIndex={0}
      // apply onKeyDown logic for downshift keyboard accessibility support
      onKeyDown={handleKeyDown}
      // apply active descendent updates to container for proper accessibility
      aria-activedescendant={toggleButtonProps?.["aria-activedescendant"]}
      {...getMenuProps?.(
        {
          ...(isListbox ? { ref: innerRef } : { ref: innerRef, role: "menu" }),
        },
        // Suppress ref error for now
        { suppressRefError: true }
      )}
      {...rest}
    >
      {children}
    </StyledMenuContent>
  );
};

MenuContent.__MENU_PRIMITIVE_TYPE = "MenuContent";

export { MenuContent };
