import React, { createContext, useMemo, useContext } from "react";
import type {
  UseComboboxReturnValue,
  UseComboboxProps,
  UseSelectReturnValue,
  UseSelectProps,
  UseMultipleSelectionReturnValue,
  UseMultipleSelectionProps,
} from "downshift";
import type { TypeTokenInputProps } from "@sproutsocial/seeds-react-token-input";
import type {
  TypeInternalItemProps,
  TypeNotEmptyChildren,
  TypeDownshiftMergedInputProps,
} from "./types";

export interface TypeDownshiftComboboxProps
  extends Partial<UseComboboxProps<TypeInternalItemProps>> {}

export interface TypeDownshiftSelectProps
  extends Partial<UseSelectProps<TypeInternalItemProps>> {}

export interface TypeDownshiftMultiSelectProps
  extends Partial<
    Omit<
      UseMultipleSelectionProps<TypeInternalItemProps>,
      "stateReducer" | "onStateChange" | "getA11yStatusMessage"
    >
  > {
  // Multi Select Props with conflicting useSelect prop names
  getA11yMultiStatusMessage?: UseMultipleSelectionProps<TypeInternalItemProps>["getA11yStatusMessage"];
  onMultiSelectStateChange?: UseMultipleSelectionProps<TypeInternalItemProps>["onStateChange"];
  multiSelectStateReducer?: UseMultipleSelectionProps<TypeInternalItemProps>["stateReducer"];
}

export interface TypeDownshiftComboboxReturnValue
  extends Partial<UseComboboxReturnValue<TypeInternalItemProps>> {}

export interface TypeDownshiftSelectReturnValue
  extends Partial<UseSelectReturnValue<TypeInternalItemProps>> {}

export interface TypeDownshiftMultiSelectReturnValue
  extends Partial<UseMultipleSelectionReturnValue<TypeInternalItemProps>> {}

type TypeDonwshiftSharedReturnValue =
  | "getLabelProps"
  | "getToggleButtonProps"
  | "getMenuProps";
type TypeDonwshiftSharedProps =
  | "onHighlightedIndexChange"
  | "onIsOpenChange"
  | "onSelectedItemChange"
  | "onStateChange"
  | "stateReducer";

export interface TypeMenuContextBaseProps {
  itemsMap?: { [key: string]: TypeInternalItemProps };
  isListbox?: boolean;
  hiddenItemsMap?: Record<string, boolean>;
  hiddenItemsCount?: number;
  setHiddenItemsMap?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  subMenuId?: string;
  setSubMenuId?: (id: string) => void;
  contentRef?: React.RefObject<HTMLUListElement>;
}

interface TypeAllDownshiftProps
  extends Omit<
      TypeDownshiftComboboxReturnValue,
      TypeDonwshiftSharedReturnValue
    >,
    Omit<TypeDownshiftComboboxProps, TypeDonwshiftSharedProps>,
    Omit<TypeDownshiftSelectReturnValue, TypeDonwshiftSharedReturnValue>,
    Omit<TypeDownshiftSelectProps, TypeDonwshiftSharedProps>,
    TypeDownshiftMultiSelectReturnValue,
    TypeDownshiftMultiSelectProps {
  onHighlightedIndexChange?:
    | TypeDownshiftComboboxProps["onHighlightedIndexChange"]
    | TypeDownshiftSelectProps["onHighlightedIndexChange"];
  onIsOpenChange?:
    | TypeDownshiftComboboxProps["onIsOpenChange"]
    | TypeDownshiftSelectProps["onIsOpenChange"];
  onSelectedItemChange?:
    | TypeDownshiftComboboxProps["onSelectedItemChange"]
    | TypeDownshiftSelectProps["onSelectedItemChange"];
  onStateChange?:
    | TypeDownshiftComboboxProps["onStateChange"]
    | TypeDownshiftSelectProps["onStateChange"];
  stateReducer?:
    | TypeDownshiftComboboxProps["stateReducer"]
    | TypeDownshiftSelectProps["stateReducer"];
  getLabelProps?:
    | TypeDownshiftComboboxReturnValue["getLabelProps"]
    | TypeDownshiftSelectReturnValue["getLabelProps"];
  getToggleButtonProps?:
    | TypeDownshiftComboboxReturnValue["getToggleButtonProps"]
    | TypeDownshiftSelectReturnValue["getToggleButtonProps"];
  getMenuProps?:
    | TypeDownshiftComboboxReturnValue["getMenuProps"]
    | TypeDownshiftSelectReturnValue["getMenuProps"];
}
export interface TypeMenuContextProps
  extends TypeAllDownshiftProps,
    TypeMenuContextBaseProps {
  items: TypeInternalItemProps[];
  itemsMap?: { [key: string]: TypeInternalItemProps };
  selectedItemIds?: { [key: string]: boolean };
  isListbox?: boolean;
}

export interface TypeMenuContext extends TypeMenuContextProps {
  itemsMap: { [key: string]: TypeInternalItemProps };
  isItemSelected: (id: string) => boolean;
  getInputComponentProps: (
    props: Pick<TypeTokenInputProps, "inputProps"> &
      Parameters<Required<TypeMenuContextProps>["getInputProps"]>[0]
  ) => Omit<TypeDownshiftMergedInputProps<TypeTokenInputProps>, "onClick">;
}

export interface TypeMenuProviderProps {
  children: TypeNotEmptyChildren;
  menuContext: TypeMenuContext;
}

export interface TypeMenuToggleContext extends TypeMenuContext {
  ref?: (arg0: React.ElementRef<any> | HTMLElement) => void;
  toggle?: () => void;
  show?: () => void;
  hide?: () => void;
  ariaProps?: Record<string, any>;
}

export interface TypeMenuToggleProviderProps extends TypeMenuProviderProps {
  menuContext: TypeMenuToggleContext;
}

/**
 * Object for Initializing the Menu Context
 */
export const defaultMenuContext: TypeMenuContext = {
  items: [],
  itemsMap: {},
  isItemSelected: () => false,
  getInputComponentProps: () => ({}),
};

export const getSelectedItemIds = <
  T extends TypeMenuContextProps["selectedItems"] | undefined,
  R = T extends undefined ? undefined : Record<string, boolean>
>(
  selectedItems?: T
): R =>
  selectedItems?.reduce(
    (selected, item) => ({ ...selected, [item.id]: true }),
    {} as Record<string, boolean>
  ) as R;

export const checkIfItemSelected = ({
  id,
  selectedItem,
  selectedItemIds,
}: {
  id: string;
  selectedItem?: TypeInternalItemProps | null;
  selectedItemIds?: TypeMenuContextProps["selectedItemIds"];
}) => (selectedItemIds ? !!selectedItemIds[id] : selectedItem?.id === id);

/**
 * Hook for initializing Menu context
 */
export const useMenu = ({
  items = [],
  isListbox = false,
  isOpen = false,
  itemsMap: itemsMapFromProps,
  selectedItemIds: selectedItemIdsFromProps,
  selectedItem,
  selectedItems,
  getInputProps,
  contentRef: contentRefFromProps,
  ...rest
}: Partial<TypeMenuContextProps> = {}): TypeMenuContext => {
  const contentRef = contentRefFromProps || React.createRef<HTMLUListElement>();
  const menuProps = useMemo(() => ({ ...rest }), [rest]);
  const itemsMap = useMemo(
    () =>
      itemsMapFromProps ||
      items.reduce((map, item) => {
        map[`${item.id}`] = {
          ...item,
        };
        return map;
      }, {} as TypeMenuContext["itemsMap"]),
    [items, itemsMapFromProps]
  );

  const selectedItemIds = useMemo(
    () => selectedItemIdsFromProps || getSelectedItemIds(selectedItems),
    [selectedItems, selectedItemIdsFromProps]
  );

  const isItemSelected = React.useCallback(
    (id: string) => checkIfItemSelected({ id, selectedItem, selectedItemIds }),
    [selectedItem?.id, selectedItemIds]
  );

  const getInputComponentProps = React.useCallback<
    TypeMenuContext["getInputComponentProps"]
  >(
    ({ inputProps, ...props }) => {
      if (!getInputProps) return {};
      const {
        ["aria-activedescendant"]: activedescendant,
        ["aria-autocomplete"]: autocomplete,
        ["aria-controls"]: controls,
        ["aria-expanded"]: expanded,
        ["aria-labelledby"]: labelledby,
        role,
        autoComplete,
        onClick,
        value,
        ...restProps
      } = getInputProps(props);
      return {
        ...restProps,
        inputProps: {
          ["aria-activedescendant"]: activedescendant,
          ["aria-autocomplete"]: autocomplete,
          ["aria-controls"]: controls,
          ["aria-expanded"]: expanded,
          ["aria-labelledby"]: labelledby,
          role,
          autoComplete,
          onClick,
          value,
          ...inputProps,
        },
      };
    },
    [getInputProps]
  );

  return {
    isOpen,
    isListbox,
    items,
    itemsMap,
    selectedItem,
    selectedItems,
    selectedItemIds,
    isItemSelected,
    getInputProps,
    getInputComponentProps,
    contentRef,
    ...menuProps,
  };
};

/**
 * MenuToggleContext factory, sets the type and the initial object
 * @description Context for the Menu Toggle
 */
export const MenuToggleContext =
  createContext<TypeMenuToggleContext>(defaultMenuContext);

/**
 * MenuToggleProvider Component sets the context values into the provider
 * @description Context Provider for the Menu Toggle
 */
export const MenuToggleProvider = ({
  children,
  menuContext,
}: TypeMenuToggleProviderProps) => {
  return (
    <MenuToggleContext.Provider value={{ ...menuContext }}>
      {children}
    </MenuToggleContext.Provider>
  );
};

/**
 * Utility hook for consuming MenuToggleContext
 */
export const useMenuToggleContext = () => {
  const menuContextValue = useContext(MenuToggleContext);

  if (menuContextValue === null) {
    throw new Error(
      "useMenuToggleContext must be used within a <MenuToggleContext.Provider />"
    );
  }

  return menuContextValue;
};

/**
 * MenuContentContext factory, sets the type and the initial object
 * @description Context for the Menu Content
 */
export const MenuContentContext =
  createContext<TypeMenuContext>(defaultMenuContext);

/**
 * MenuContentProvider Component sets the context values into the provider
 * @description Context Provider for the Menu Content
 */
export const MenuContentProvider = ({
  children,
  menuContext,
}: TypeMenuProviderProps) => {
  return (
    <MenuContentContext.Provider value={{ ...menuContext }}>
      {children}
    </MenuContentContext.Provider>
  );
};

/**
 * Utility hook for consuming MenuContentContext
 */
export const useMenuContentContext = () => {
  const menuContextValue = useContext(MenuContentContext);

  if (menuContextValue === null) {
    throw new Error(
      "useMenuContentContext must be used within a <MenuContentContext.Provider />"
    );
  }

  return menuContextValue;
};
