import { useCombobox, useSelect } from "downshift";

export const nestedMenuStateReducer = (_state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;
  const hasChildMenu = !!changes?.selectedItem?.menuItemProps?.childMenuId;
  if (!hasChildMenu) {
    return changes;
  }
  switch (type) {
    case useSelect.stateChangeTypes.ToggleButtonBlur:
    case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
    case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
    case useSelect.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true,
        highlightedIndex: 0,
      };
    default:
      return changes;
  }
};
