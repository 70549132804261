import React, { type ReactNode, useMemo } from "react";
import { MenuItem, type TypeMenuItemProps } from "../MenuItem";
import type {
  TypeSubmenuItemProps,
  TypeSubmenuValidMenuComponentProps,
} from "../SubmenuItem";
import type { TypeMenuContext } from "../MenuContext";
import type { TypeMenuGroupProps, TypeMenuGroupBaseProps } from "../MenuGroup";
import type { TypeMenuRootProps } from "../MenuRoot";
import type {
  TypeNotEmptyChildren,
  TypeChildrenOrItems,
  TypeInternalItemProps,
} from "../types";
import { MenuContent, type TypeMenuContentProps } from "../MenuContent";

export interface TypeMapMenuItemsProps<
  I extends TypeMenuItemProps = TypeMenuItemProps
> {
  menuItems: I[];
  MenuItemComponent?: React.ComponentType<I>;
}
export type TypeItemElement<I extends TypeMenuItemProps = TypeMenuItemProps> =
  React.ReactElement<
    | TypeMenuItemProps
    | TypeSubmenuItemProps<I, TypeSubmenuValidMenuComponentProps<I>>
  >;
export type TypeMenuGroupElement<
  I extends TypeMenuItemProps = TypeMenuItemProps
> = React.ReactElement<TypeMenuGroupProps<I>>;
export type TypeMenuContentElement<
  I extends TypeMenuItemProps = TypeMenuItemProps
> = React.ReactElement<TypeMenuContentProps<I>>;

export interface TypeMenuChildren {
  type: "item" | "group" | "content";
  element: TypeItemElement | TypeMenuGroupElement | TypeMenuContentElement;
}

// https://stackoverflow.com/questions/69119016/find-specific-children-components-regardless-of-depth-in-react
const walkAllChildren = (
  children: ReactNode,
  callback: (element: ReactNode, parents: readonly ReactNode[]) => void
) => {
  const walk = (element: ReactNode, parents: readonly ReactNode[]) => {
    if (element === null || element === undefined) return;
    callback(element, parents);
    const newParents = [...parents, element];
    const children = (element as any).props?.children;
    React.Children.toArray(children).forEach((child) => {
      walk(child, newParents);
    });
  };
  const parents = [];
  React.Children.toArray(children).forEach((child) => {
    walk(child, parents);
  });
};

// get the name from our custom __MENU_PRIMITIVE_TYPE property
const getComponentName = (element: ReactNode): string | undefined => {
  if (
    element &&
    typeof element === "object" &&
    "type" in element &&
    (typeof element.type === "function" || typeof element.type === "object") &&
    "__MENU_PRIMITIVE_TYPE" in element.type &&
    typeof element.type.__MENU_PRIMITIVE_TYPE === "string"
  ) {
    return element.type.__MENU_PRIMITIVE_TYPE;
  }
};

// Add a menu item to the provided allMenuItems array and menuItemsMap object
const addMenuItem = ({
  itemProps,
  allMenuItems,
  menuItemsMap,
}: {
  itemProps: Omit<TypeInternalItemProps, "index">;
  allMenuItems: TypeMenuContext["items"];
  menuItemsMap: TypeMenuContext["itemsMap"];
}) => {
  const item = {
    ...itemProps,
    index: allMenuItems.length,
  };
  allMenuItems.push(item);
  menuItemsMap[`${item.id}`] = item;
};

// Add items from a MenuGroup element to the allMenuItems array and menuItemsMap object
const addItemsFromGroup = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  groupElement: menuGroup,
  allMenuItems,
  menuItemsMap,
}: {
  groupElement: TypeMenuGroupElement<I>;
  allMenuItems: TypeMenuContext["items"];
  menuItemsMap: TypeMenuContext["itemsMap"];
}) => {
  const menuItems = menuGroup.props.menuItems;
  if (menuItems) {
    menuItems.forEach((menuItem) => {
      addMenuItem({
        itemProps: {
          id: menuItem.id,
          disabled: menuItem.disabled,
          menuItemProps: menuItem,
          menuGroupProps: menuGroup.props,
        },
        allMenuItems,
        menuItemsMap,
      });
    });
  } else if (menuGroup.props.children) {
    let children = menuGroup.props.children as TypeItemElement[];
    // If there is only one child, it will be an object. Make it an array.
    if (!Array.isArray(children)) {
      children = [children];
    }
    children.forEach((menuItem) => {
      addMenuItem({
        itemProps: {
          id: menuItem.props.id,
          disabled: menuItem.props.disabled,
          menuItemProps: menuItem.props,
          menuGroupProps: menuGroup.props,
        },
        allMenuItems,
        menuItemsMap,
      });
    });
  }
};

// Add items from a MenuContent element to the allMenuItems array and menuItemsMap object
const addItemsFromContent = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  contentElement: menuContent,
  allMenuItems,
  menuItemsMap,
}: {
  contentElement: TypeMenuContentElement<I>;
  allMenuItems: TypeMenuContext["items"];
  menuItemsMap: TypeMenuContext["itemsMap"];
}) => {
  /**
   * No need to add items inside of MenuContent if it does not have menuItems
   * since we already traversed the children and menuContentProps are not needed.
   */
  if (!("menuItems" in menuContent.props)) return;
  // Add items from menuItems prop if it isn't empty
  if (menuContent.props.menuItems) {
    menuContent.props.menuItems.forEach((menuItem) => {
      addMenuItem({
        itemProps: {
          id: menuItem.id,
          disabled: menuItem.disabled,
          menuItemProps: menuItem,
        },
        allMenuItems,
        menuItemsMap,
      });
    });
  }
};

// Traverse the children of a Menu component and add items to the allMenuItems array and menuItemsMap object
const setMenuItemsFromChildren = ({
  children,
  allMenuItems,
  menuItemsMap,
}: {
  children: ReactNode;
  allMenuItems: TypeMenuContext["items"];
  menuItemsMap: TypeMenuContext["itemsMap"];
}) => {
  walkAllChildren(children, (element) => {
    switch (getComponentName(element)) {
      case "MenuItem":
        // Cast element to the TypeItemElement since we know it's one of the three.
        // This is needed for the addMenuItem function.
        const menuItem = element as TypeItemElement;
        // Items in groups would have already been added to the map.
        if (menuItemsMap[`${menuItem.props.id}`]) return;
        addMenuItem({
          itemProps: {
            id: menuItem.props.id,
            disabled: menuItem.props.disabled,
            menuItemProps: menuItem.props,
          },
          allMenuItems,
          menuItemsMap,
        });
        break;
      case "MenuGroup":
        addItemsFromGroup({
          // Cast element to the TypeMenuGroupElement since we know it's a group and is needed for addItemsFromGroup.
          groupElement: element as TypeMenuGroupElement,
          allMenuItems,
          menuItemsMap,
        });
        break;
      case "MenuContent":
        addItemsFromContent({
          // Cast element to the TypeMenuContentElement since we know it's a content and is needed for addItemsFromContent.
          contentElement: element as TypeMenuContentElement,
          allMenuItems,
          menuItemsMap,
        });
        break;
      default:
        break;
    }
  });
};

// Returns a fragment containing a `MenuItemComponent` for each item in `menuItems`.
export const mapMenuItems = <I extends TypeMenuItemProps = TypeMenuItemProps>({
  menuItems,
  MenuItemComponent = MenuItem,
}: TypeMapMenuItemsProps<I>) => {
  return (
    <>
      {menuItems.map((menuItemProps) => (
        <MenuItemComponent key={menuItemProps.id} {...menuItemProps} />
      ))}
    </>
  );
};

type TypeUseMenuChildrenProps<I extends TypeMenuItemProps = TypeMenuItemProps> =
  {
    children?: TypeMenuRootProps["children"];
    menuItems?: I[];
    MenuItemComponent?: React.ComponentType<I>;
  };

// Return a MenuContent using the provided menuItems
export const getChildrenFromMenuItems = <
  I extends TypeMenuItemProps = TypeMenuItemProps
>({
  menuItems,
  MenuItemComponent,
}: {
  menuItems: I[];
  MenuItemComponent?: React.ComponentType<I>;
}) => (
  <MenuContent menuItems={menuItems} MenuItemComponent={MenuItemComponent} />
);

// Hook to get all menu items and children for a Menu component
export const useMenuChildren = <
  I extends TypeMenuItemProps = TypeMenuItemProps
>({
  children: childrenProp,
  menuItems,
  MenuItemComponent,
}: TypeUseMenuChildrenProps<I>) => {
  const { allMenuItems, menuItemsMap, children } = useMemo(() => {
    let children: TypeNotEmptyChildren = <></>;
    const menuItemsMap: TypeMenuContext["itemsMap"] = {};
    const allMenuItems: TypeMenuContext["items"] = [];

    // If an menuItems prop was passed to the Menu, we can bypass the children traversal.
    if (menuItems && menuItems.length) {
      menuItems.forEach((menuItem) => {
        addMenuItem({ itemProps: menuItem, allMenuItems, menuItemsMap });
      });
      children = getChildrenFromMenuItems({ menuItems, MenuItemComponent });
    } else if (childrenProp) {
      children = childrenProp as TypeNotEmptyChildren;
      setMenuItemsFromChildren({
        children: childrenProp,
        allMenuItems,
        menuItemsMap,
      });
    }

    return { allMenuItems, menuItemsMap, children };
  }, [childrenProp, menuItems, MenuItemComponent]);

  return { allMenuItems, menuItemsMap, children };
};
