import React from "react";
import {
  MenuHeaderContainer,
  MenuTitleText,
  MenuChildrenContainer,
  MenuHeaderFeatures,
} from "./styles";
import type { TypeMenuHeaderProps } from "./MenuHeaderTypes";

/**
 * @link https://seeds.sproutsocial.com/components/menu-header/
 *
 * @description MenuHeader is a container used to hold header content within a Menu.
 *
 * @see {@link https://seeds.sproutsocial.com/components/menu-footer/ | MenuFooter}
 */

const MenuHeader = ({
  children,
  title,
  leftAction,
  rightAction,
  ...rest
}: TypeMenuHeaderProps) => {
  const showTopRow = title || leftAction || rightAction;
  return (
    <>
      <MenuHeaderContainer $leftAction={!!leftAction} {...rest}>
        {showTopRow && (
          <MenuHeaderFeatures $leftAction={!!leftAction}>
            {leftAction && leftAction}
            {title && (
              <MenuTitleText $leftAction={!!leftAction}>{title}</MenuTitleText>
            )}

            {rightAction && <div className="right-action">{rightAction}</div>}
          </MenuHeaderFeatures>
        )}

        {children && <MenuChildrenContainer>{children}</MenuChildrenContainer>}
      </MenuHeaderContainer>
    </>
  );
};

export { MenuHeader };
