import React from "react";
import { useCombobox } from "downshift";
import { useMenuChildren } from "../hooks/useMenuChildren";
import { itemToString as defaultItemToString } from "../utils/downshiftDefaults";
import { reduceReducers } from "../utils/reduceReducers";
import { useDownshiftDefaults } from "../hooks/useDownshiftDefaults";
import { useItemFiltering } from "../hooks/useItemFiltering";
import { MenuRoot } from "../MenuRoot";

import type { TypeMenuItemProps } from "../MenuItem";
import type { TypeMenuRootProps } from "../MenuRoot";
import type { TypeAutocompleteProps } from "./AutocompleteTypes";

export const SingleAutocomplete = <
  I extends TypeMenuItemProps = TypeMenuItemProps
>({
  children: childrenProp,
  stateReducer: externalStateReducer,
  menuItems,
  MenuItemComponent,
  itemToString = defaultItemToString,
  getIsItemVisible,
  ...useComboboxProps
}: TypeAutocompleteProps<I>) => {
  /** Get a list of menuItems and children */
  const { allMenuItems, children } = useMenuChildren({
    children: childrenProp,
    menuItems,
    MenuItemComponent,
  });
  const { setHiddenItemsMap, defaultDownshiftProps, ...restDefaults } =
    useDownshiftDefaults({ isCombobox: true });
  const { updateFilteredItems } = useItemFiltering({
    allMenuItems,
    itemToString,
    getIsItemVisible,
    setHiddenItemsMap,
  });

  /**
   * Destructure Downshift props and call the core useCombobox with items and state handler
   */
  const { isOpen, ...useComboboxReturnProps } = useCombobox({
    ...defaultDownshiftProps.combobox,
    items: allMenuItems,
    itemToString,
    onInputValueChange: updateFilteredItems,
    stateReducer: reduceReducers(
      defaultDownshiftProps.combobox.stateReducer,
      externalStateReducer
    ),
    ...useComboboxProps,
  });

  return (
    <MenuRoot
      {...{
        isListbox: true,
        items: allMenuItems,
        itemToString,
        isOpen,
        setHiddenItemsMap,
        ...useComboboxProps,
        ...useComboboxReturnProps,
        ...restDefaults,
      }}
      popoutProps={{
        focusOnContent: false,
      }}
    >
      {children}
    </MenuRoot>
  );
};
