import React from "react";
import { Label, type TypeLabelProps } from "@sproutsocial/seeds-react-label";
import { useMenuToggleContext } from "./MenuContext";

export const MenuLabel = ({ children, ...rest }: Partial<TypeLabelProps>) => {
  const { getLabelProps } = useMenuToggleContext();
  return (
    <Label htmlFor="fallback_menu_label" {...getLabelProps?.()} {...rest}>
      {children}
    </Label>
  );
};
