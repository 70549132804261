import styled, { css } from "styled-components";
import { focusRing, disabled } from "@sproutsocial/seeds-react-mixins";
import { color, border, flexbox, grid, layout, space } from "styled-system";
import {
  CheckboxContainer,
  InputWrapper,
  PillInput,
  checkboxContainerHoverStyles,
  inputWrapperHoverStyles,
  pillInputHoverStyles,
} from "@sproutsocial/seeds-react-checkbox";

const highlightedStyles = css`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.listItem.border.base};
  background-color: ${({ theme }) => theme.colors.listItem.background.hover};
  color: ${({ theme }) => theme.colors.text.body};
  text-decoration: none;
`;

const activeStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.listItem.border.base};
  background-color: ${({ theme }) => theme.colors.menuItem.background.active};
  color: ${({ theme }) => theme.colors.text.body};
  text-decoration: none;
`;

export const MenuItemContainer = styled.li`
  box-sizing: border-box;
  list-style-type: none;
  margin-left: ${({ theme }) => theme.space[300]};
  margin-right: ${({ theme }) => theme.space[300]};

  &:first-child {
    margin-top: ${({ theme }) => theme.space[300]};
  }

  &:last-child {
    margin-bottom: ${({ theme }) => theme.space[300]};
  }
`;

export const StyledMenuItem = styled.button<{
  $highlighted: boolean;
  $active?: boolean;
}>`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii[500]};
  background-color: ${({ theme }) => theme.colors.listItem.background.base};
  border: 1px solid ${({ theme }) => theme.colors.listItem.background.base};
  color: ${({ theme }) => theme.colors.text.body};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  text-align: left;
  text-decoration: none;
  padding: ${({ theme }) => theme.space[300]};
  outline: 0;
  ${({ theme }) => theme.typography[200]};
  transition: all ${({ theme }) => theme.duration["fast"]};

  /* Highlighted */
  ${({ $highlighted }) => $highlighted && highlightedStyles}

  /* Hovered, but not focused */
  &:hover {
    ${highlightedStyles}
    ${CheckboxContainer} {
      ${checkboxContainerHoverStyles}
    }
    ${InputWrapper} {
      ${inputWrapperHoverStyles}
    }
    ${PillInput} {
      ${pillInputHoverStyles}
    }
  }

  /* focused */
  &:focus {
    ${focusRing}
  }

  /* Active */
  ${({ $active }) => $active && activeStyles}

  /* Pressed state*/
  &:active {
    ${activeStyles}
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    ${disabled}
  }

  ${border}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${space}
`;

export const MenuItemRow = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
`;
export const MenuItemAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const MenuItemActionLeft = styled(MenuItemAction)`
  margin-right: ${({ theme }) => theme.space[300]};
`;

export const MenuItemActionRight = styled(MenuItemAction)`
  margin-left: ${({ theme }) => theme.space[300]};
`;

export const MenuItemText = styled.div`
  word-break: break-word;
  flex-grow: 1;
  min-width: 0;
`;
